'use strict'

###*
 # @ngdoc object
 # @name mundoReporting.controller:ReportMapCtrl

 # @description

###
class ReportMapCtrl
  ### @ngInject ###
  constructor:(
    $scope
    $log
    $filter
    $window
    $timeout
    $mdDialog
    $stateParams
    $location
    ReportsManager
    MundoMap
    ServiceReportVisualizeManager
    ColorService
    _
    tmhDynamicLocale
    TokenUtils
  ) ->
    tmhDynamicLocale.set('nl-be')
    @ctrlName = 'ReportMapCtrl'

    @sharesId = $stateParams.sharesId
    @configId = $stateParams.configId
    @accessToken = null
    @config = null
    @map = null

    @params = $location.search()

    @autorefresh = @params.autorefresh
    @hideControls = @params.hidecontrols
    @autocenter = @params.autocenter
    @zoomlevel = @params.zoomlevel
    @follow = @params.follow
    @layerConfig = @params.layerconfig


    $window.renderingfinished = false
    TokenUtils.getClientToken()
    .then (response) =>
      @accessToken = response.data.access_token
      ColorService.resetColorsWithAuth(
        @configId
        @sharesId
        @accessToken
      )
      ReportsManager.getReportConfiguration(
        @configId
        @sharesId
        @accessToken
      )
      .then (config) =>
        @init(config.data)
        @getConfig() if @autorefresh?
      , (error) -> console.log(error)
    , (error) -> console.log(error)

    @getConfig = () =>
      if @autorefresh?
        $timeout(=>
          ReportsManager.getReportConfiguration(@configId, @sharesId, @accessToken)
            .then(
              (config) =>
                @config = config.data
                MundoMap.getLayerSource(@mapElementsLayer).clear()
                MundoMap.getLayerSource(@mapTripsLayer).clear()
                @setMapLayers()

                @getConfig()

              (error) -> console.log(error)
            )
        ,@autorefresh*1000)

    @init = (config) =>
      @config = config
      # Set the id of the current map
      dt = new Date().getTime()
      @mapId = "map-#{@config['_id']}-#{dt}"

      # when param follow was added to URL no need to enable it
      if @follow == 'false'
        @followEnabled = false
      else
        @followEnabled = true

      #wait for the map id to be loaded in the html
      $timeout(=>
        $log.debug "CONFIGURATION =====", @config
        # Create a new instance of a map, with the mapId
        @map = MundoMap.createInstance @mapId,
          follow:
            objects: []
            enabled: @followEnabled
            speedZoom: false
          fillScreen: true
          search:
            enabled: false

        @map.updateSize()

        #initialise layers
        @mapElementsLayer = @getMapLayer 'markers'
        @mapTripsLayer = @getMapLayer 'trips'

        # @mapTripsLayer.on(
        #   'change'
        #   (=> console.log @mapTripsLayer.getVisible())
        #   )

        @removeControls() if @hideControls
        @setMapLayers()

        # check if URL param is set to override default setting
        if @layerConfig == 'true'
          # get baseLayer and activate it
          @userData = JSON.parse(@config.userData)
          if @userData.mapLayers
            @baseLayer = @userData.mapLayers[0]
            @getMapLayer(@baseLayer).setVisible true

        # if userData is available (with a defined zoomLevel and mapcenter)
        @setZoomAndCenter @config.userData if @config.userData

      ,50)

      @removeControls = =>
        controls = @map.getControls()
        try
          control = controls.pop()
          while control != undefined
            control = controls.pop()
        catch exception
          #do nothing


      @getMapLayer = (layerId)=>
        MundoMap.getLayerById @map, layerId

      @setMarkersStyle = =>
        # Set the style of the markers
        @mapElementsLayer.setStyle (feature) ->
          isCluster = false
          if feature.get('features')?
            allFeatures = feature.get('features')
            if (angular.isArray(allFeatures))
              size = allFeatures.length
              if size == 1
                if allFeatures[0].get('_color')?
                  bgColor = '#' + allFeatures[0].get('_color')
                else
                  bgColor = '#FFFFFF'
                textColor = '#222222'
                iconType = 'location'
                opacity = 0.8

                style = allFeatures[0].get('_cachedStyle')

                if not style
                  style = [
                      new ol.style.Style
                        image: new ol.style.Circle
                          radius: 17
                          stroke: new ol.style.Stroke
                            # opacity: 0.7
                            # color: '#222222'
                            color: 'rgba(0, 0, 0, 0.8)'
                            width: 3
                          fill: new ol.style.Fill
                            # opacity: 0.8
                            # color: '#FFFFFF'
                            color: 'rgba(255, 255, 255, 0.8)'
                      new ol.style.Style
                        image: new ol.style.Circle
                          radius: 17
                          stroke: new ol.style.Stroke
                            # opacity: 0.7
                            # color: '#222222'
                            color: bgColor
                            width: 2
                      new ol.style.Style
                        image: new ol.style.Icon
                          anchor: [0.5, 0.5]
                          anchorXUnits: 'fraction'
                          anchorYUnits: 'fraction'
                          snapToPixel: true
                          opacity: 1
                          crossOrigin: "anonymous"
                          src: allFeatures[0].get('_item').markerUrl
                          color: bgColor
                  ]

                allFeatures[0].set '_cachedStyle', style
              else
                style = feature.get('_cachedStyle')
                options =
                  opacity: 1
                  radius: 15
                  strokeColor: [255,255,255,0.8]
                  fillColor: '#3399CC'
                  textColor: '#FFFFFF'
                  label: size.toString()

                if not style
                  style = new ol.style.Style
                    image: new ol.style.Circle
                      radius: options.radius
                      stroke: new ol.style.Stroke
                        color: options.strokeColor
                        width: 2
                      fill: new ol.style.Fill
                        color: options.fillColor
                    text: new ol.style.Text
                      text: options.label
                      scale: 1.5
                      fill: new ol.style.Fill
                        color: options.textColor

                  feature.set '_cachedStyle', style

              return style
          else
            return null

      @setMapLayers = =>
        @setMarkersStyle()

        params = @params
        params.visualizer = 'JSON'
        # params.configuration = @config['_id']
        params.limit = if @params.limit? then @params.limit else 25
        params.offset = if @params.offset? then @params.offset else 0
        ReportsManager.getReportVisualizers(@configId, @sharesId, @accessToken, params)
          .then (reports) =>
            if reports.length == params.limit
              @reachedEndOfReportItems = false
            else
              @reachedEndOfReportItems = true
            @reportItems = reports.data

            sessionfilters = getSessionIdsFromParams @params
            if sessionfilters.length? and sessionfilters.length > 0
              @setFilteredMarkers()
            else
              @setMarkers()
              @setMapTrips()

      @setZoomAndCenter = (userData) =>
        @userData = JSON.parse(userData)
        if @userData.zoomLevel && @zoomlevel
          @map.getView().setZoom @userData.zoomLevel
        if @userData.mapCenter && @autocenter == 'false'
          @map.getView().setCenter @userData.mapCenter


    getSessionIdsFromParams = (params) ->
      ids = []
      count = 0
      paramname = "filter[#{count}]"
      while params[paramname]?
        ids.push params[paramname].split(',')[1] if params[paramname].split(',')[0] is 'sessions.id'
        count += 1
        paramname = "filter[#{count}]"

      ids

    @setMarkers = =>
      # Determine layer sources
      markerSource = MundoMap.getLayerSource @mapElementsLayer

      locationfilter = (field) ->
        'point' in field.field.split '.'

      locationFields = _.filter @config.fields, locationfilter
      locationFields = if locationFields then angular.copy(locationFields) else []

      #initialise colours when offset is given
      ColorService.getColorBySeed seed for seed in [1..@params.offset]

      # Loop all reportItems
      angular.forEach @reportItems, (reportItem, key) =>
        index = key

        # Determine marker
        if reportItem.unit? and reportItem.unit.id?
          reportItem.markerUrl = MundoMap.getMarkerPath reportItem.unit.id
        # Iterate over location fields
        angular.forEach locationFields, (field) =>
          # For each location-based field, get the point and create a geometry + feature
          location = @getFieldData(field.field, reportItem)
          # console.log reportItem[field.field]
          if not location
            return
          #console.log reportItem

          # Determine feature color
          color = null

          color = ColorService.getColorBySeed reportItem['_id']
          geometry = MundoMap.getGeometryFromGeoJSON location

          feature = new ol.Feature
            geometry: geometry
            name: reportItem['_id']
          feature.setProperties
            _item: reportItem
            _itemIndex: index

            _color: color
            _tags: [
              reportItem.label
            ]

          if (not reportItem.color?) and color?
            reportItem.color = color

          # Add feature to the layer
          markerSource.addFeature feature

    @setFilteredMarkers = =>
      markerSource = MundoMap.getLayerSource @mapElementsLayer

      # Loop all reportItems
      angular.forEach @reportItems, (reportItem, key) ->
        index = key

        # For each location-based field, get the point and create a geometry + feature
        location = reportItem.location.point
        if not location
          return
        #console.log reportItem
        reportItem.markerUrl = MundoMap.getMarkerPath reportItem.unit.id
        # Determine feature color
        color = null

        color = ColorService.getColorBySeed reportItem.sessions[0].id
        geometry = MundoMap.getGeometryFromGeoJSON location

        feature = new ol.Feature
          geometry: geometry
          name: reportItem['_id']
        feature.setProperties
          _item: reportItem
          _itemIndex: 0
          _color: color
          _tags: [
            reportItem.label
          ]

        if (not reportItem.color?) and color?
          reportItem.color = color

        # Add feature to the layer
        markerSource.addFeature feature
      $window.renderingfinished = true
      @centerFeatures()

    @setMapTripsStyle = =>
      @mapTripsLayer.setStyle (feature) ->
        style = [
          new ol.style.Style
            stroke: new ol.style.Stroke
              color: '#222222'
              width: 5
          new ol.style.Style
            stroke: new ol.style.Stroke
              color: feature.get('_color')
              width: 3
        ]

        return style


    @setMapTrips = =>
      #only mappableif unit sessions, at the moment
      @mappable = @config.source == 'MongoUnitSession'

      @setMapTripsStyle()

      if @mappable
        #counter to keep track of how many items should be rendered
        @counter = 0
        angular.forEach @reportItems, (reportItem) =>
          @counter++
          color = ReportColorService.getColorBySeed reportItem['_id']

          params = {}
          params.visualizer = 'JSON'
          params['filter[0]'] = "sessions.id,#{reportItem['_id']}"

          ReportsManager.getReportVisualizers(
            @config.links[0].targetId
            @sharesId
            @accessToken
            params
          )
            .then (linkReports) =>
              locations = []
              angular.forEach linkReports.data, (linkreport, key) ->
                if linkreport.location && linkreport.location.point
                  locations.push linkreport.location.point.coordinates if linkreport.location.point

              geom = MundoMap.getGeometryFromGeoJSON
                type: 'LineString'
                coordinates: locations
              feature = new ol.Feature
                geometry: geom
              feature.setProperties
                _color: '#' + color
              # Add feature to the layer

              MundoMap.getLayerSource @mapTripsLayer
                .addFeature feature
              @counter--
              if @counter == 0
                $window.renderingfinished=true
                @centerFeatures()
      else
        $window.renderingfinished=true
        @centerFeatures()

    @centerFeatures = =>

      if @autocenter == true
        features = MundoMap.getLayerSource @mapElementsLayer
          .getFeatures()
        if not features.length
          return

        extent = ol.extent.createEmpty()

        for feature in features
          ol.extent.extend extent, feature.getGeometry().getExtent()

        if @map._mundoMapOptions.follow.enabled
          @map
            .getView()
            .fit extent,
              padding: [5, 5, 5, 5]
              constrainResolution: true



    # Method to get the right value of a certain key for a row in the datatable.
    @getFieldData = (path, returnData, delimiter = '.', wildcard = '*', needsCompleteReturn = false) ->
      if (needsCompleteReturn) || (path.length == 0)
        return returnData

      # Split the key to go as deep as possible in the object/dictionary.
      parts = path.split delimiter

      for part, key in parts
        if part == '*'
          if angular.isObject returnData

            tempPath = (parts.slice(key + 1)).join delimiter
            path = (parts.slice(0, key)).join delimiter
            if angular.isArray returnData
              tempResult = []
              for subData, jKey in returnData
                if tempPath && tempPath.length > 0
                  tempResult[jKey] = @getFieldData(tempPath, subData, delimiter, wildcard)
                else
                  tempResult[jKey] = @getFieldData(tempPath, subData, delimiter, wildcard, true)
              returnData = tempResult
            else
              tempResult = {}
              for jKey, subData of returnData
                tempResult[jKey] = @getFieldData(tempPath, subData, delimiter, wildcard, true)
              returnData = tempResult
            break
          else
            break
        else
          try
            if angular.isDefined returnData[part]
              returnData = returnData[part]
            else
              returnData = null
              break
          catch
            returnData = null
      return returnData

angular
  .module('mundoReporting')
  .controller 'ReportMapCtrl', ReportMapCtrl
